<!-- 技术团队 -->

<template>
  <div class="box">
    <div class="nav_top">
      <div class="top"><top-nav></top-nav></div>
      <!-- <h1>环保装备交易平台</h1>
      <h2>服务环境产业上下游</h2> -->
    </div>
    <div class="content_c">
      <div class="content_list" v-for="(item, index) in newsList" :key="index" @click="goDetails(item.pid)">
        <div class="img" v-if="item.pathUpload">
          <img :src="item.pathUpload" alt="" />
        </div>
        <div class="list_content">
          <div class="title">{{ item.title }}</div>
          <div class="text">
            <div class="content">{{item.other}}</div>
          </div>
          <div class="time">时间{{ item.createTime }}</div>
        </div>
        <!-- <div class="details" ></div> -->
      </div>
      <div class="pagination">
        <el-button size="small" @click="pervious">上一页</el-button>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage1"
          background
          :page-size="pageSize"
          layout="pager"
          :total="total"
          ref="pageGroup"
        >
        </el-pagination>
        <el-button size="small" @click="next">下一页</el-button>
      </div>
    </div>
    <div class="content5">
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import { listPain } from "../../api/api";
import topNav from "../../components/topNav";
import bottom from "../../components/bottom";
import { province } from "../../api/api";

export default {
  components: {
    topNav,
    bottom,
  },
  data() {
    return {
      userInfo: "",
      newsList: [],
      partnerId: undefined,
      currentPage1: 1,
      total: 0,
      pageSize: 5,
    };
  },
  beforeMount() {
    let _province = localStorage.getItem("province");
    if (!_province) {
      this.getprovince();
    }
  },
  mounted() {
    this._intoNews();
  },  watch:{
			// 此处监听variable变量，当期有变化时执行
			pid(item1,item2){
				// item1为新值，item2为旧值
        console.log(item1,'新',item2,'旧');
			}
		},
  methods: {
    //获取省市
    async getprovince() {
      let res = await province();
      if (res.ResultCode == 0) {
        console.log(res, "res");
        localStorage.setItem("province", JSON.stringify(res.Data));
      }
    },
    pervious() {
      this.$refs.pageGroup.prev();
    },
    next() {
      this.$refs.pageGroup.next();
    },
    goDetails(e) {
      
      this.$router.push({
        path: '/listDetails',
        query: {
          pid: e,
        },
      });
    },
    //获取文章列表
    async _intoNews() {
      console.log(this.$route.query.pid,'this.$route.query.pid',this.$router.path);
       let pid= this.$route.query.pid
      let res = await listPain({
        typeidSelsource: pid,
        page: this.currentPage1,
        limit: this.pageSize,
      });
      if (res.ResultCode == 0) {
        console.log(res.Data, "res");
        this.newsList = res.Data.Items;
        this.total =res.Data.TotalRecordCount
      }
    },
    async handleCurrentChange(val) {
      this.currentPage1=val
      this._intoNews()
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  box-sizing: border-box;
  .nav_top {
    width: 100%;
    height: 540px;
    background-image: url("../../assets/banner_mao.png");
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    .top {
      width: 100%;
      height: 100px;
      background-size: 100% 100px;
      background-repeat: no-repeat;
    }
    h1 {
      font-size: 66px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-top: 140px;
    }
    h2 {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba($color: #ffffff, $alpha: 0.8);
      text-align: center;
      margin-top: 51px;
    }
    .bom {
      margin: 0 250px;
      margin-top: 207px;
      display: flex;
      flex: 1;
      justify-content: space-between;
      .list_style {
        padding-right: 137px;
        .title {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba($color: #ffffff, $alpha: 0.8);
        }
        .title2 {
          font-size: 34px;
          font-family: DIN Alternate;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          margin-top: 25px;
        }
      }
    }
  }
  .content_c {
    width: 65%;
    margin: 0 auto;
    // padding-top: 172px;
    .content_list {
      cursor: pointer;
      width: 100%;
      height: 216px;
      margin: 0 auto;
      margin-top: 62px;
      display: flex;
      justify-content: flex-start;
      position: relative;
      .img {
        width: 342px;
        height: 216px;
        img{
          width: 342px;
          height: 216px;
        }
      }
      .list_content {
        width: 100%;
        padding: 20px;
        height: 216px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #eee;
        .title {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
        }
        .text {
          height: 100px;
          .content {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            // 两行显示
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .time {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 31px;
        }
      }
      .details {
        width: 60px;
        height: 30px;
        position: absolute;
        bottom: 3px;
        right: 0;
        background-image: url("../../assets/newList/details.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .details:hover {
        background-image: url("../../assets/newList/details_select.png");
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 30px;
      line-height: 30px;
      margin: 20px auto;
    }
  }
  .content5 {
    width: 100%;
    height: 310px;
    background: #f5f5f5;
  }
}
</style>
